import httpService from '@/services/http.service';
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import Appels from '@/store/plugins/appels';
import { SERVER_VALIDATION_ERROR } from '@/store/enums';

const initialState = {
  isLoading: false,
  isEditing: false,
  isSubmitting: false,
  isLoaded: false,
  entities: {},
  selectedEntityId: null,
};

const actions = {
  fetchAll({ commit }, payload) {
    commit('setIsLoading', true);

    const q = payload && (payload.queryParams || null);
    httpService.get(getUrlWithQueryParams('/plans-passation-marches', q)).then((response) => {
      commit('setEntities', { entities: response.data });
      commit('setIsLoading', false);
    });
  },

  fetchCurrentUserPlans({ commit }, payload) {
    commit('setIsLoading', true);

    const q = payload && (payload.queryParams || null);
    httpService.get(getUrlWithQueryParams('/plans-passation-marches/GetCurrentPlans', q)).then((response) => {
      commit('setEntities', { entities: response, attr: 'annee' });
      commit('setIsLoading', false);
      commit(
        'projetsMarches/setEntities',
        {
          entities: {
            data: response.data.reduce((ppmList, ppmItem) => ppmList.concat(ppmItem.projetMarches), []) || [],
          },
        },
        { root: true }
      );
      commit('setSelectedEntityId', new Date().getFullYear());
    });
  },

  async fetchAllByEntrepriseId({ commit }, { entrepriseId }) {
    commit('setIsLoading', true);
    httpService.get(`/plans-passation-marches?entrepriseId=${entrepriseId}`).then((response) => {
      commit('setEntities', { entities: response.data, attr: 'annee' });
      commit('setIsLoading', false);

      commit('setSelectedEntityId', response.data.data[0].annee);
      // dispatch('ami/fetchAllByPlanId', { planId: response.data.data[].id });
      // const currentPlan = response.data.data.find(plan => plan.annee === new Date().getFullYear());
      // if (currentPlan)
      //  dispatch("appelsOffres/fetchAllByPlanId", { planId: currentPlan.id }, { root: true });
    });
  },

  async fetchAllIncludeAvisManifestationInteret({ commit }, { entrepriseId }) {
    commit('setIsLoading', true);
    httpService.get(`/plans-passation-marches?entrepriseId=${entrepriseId}`).then((response) => {
      commit('setEntities', { entities: response.data, attr: 'annee' });
      commit('setIsLoading', false);
      /*const currentPlan = response.data.data.find(plan => plan.annee === new Date().getFullYear());
      if (currentPlan)
        dispatch("ami/fetchAllByPlanId", { planId: currentPlan.id }, { root: true });*/
    });
  },

  update({ commit }, { data, payload }) {
    commit('setIsSubmitting', true);

    const q = payload && (payload.queryParams || null);

    httpService
      .put(getUrlWithQueryParams(`/plans-passation-marches/${data.id}`, q), data)
      .then((response) => {
        const entity = response.data;
        if (payload?.isAdmin !== undefined && payload.isAdmin === true) commit('setEntity', { entity });
        else commit('setEntity', { entity, attr: 'annee' });

        commit('setIsEditing', false);
        commit('setIsSubmitting', false);
      })
      .catch((error) => {
        console.log({ error });
        const errorResponse = error.response;
        const isValidationError = errorResponse?.data?.message === SERVER_VALIDATION_ERROR;

        const errors = {
          validationError: isValidationError,
          errorList: errorResponse?.data?.errors,
        };
        if (isValidationError) commit('setErrors', { errors });
        commit('setIsSubmitting', false);
      });
  },
};

const mutations = {
  ...helpers.mutations,

  addProjetMarcheToPlan(state, projetMarche) {
    const currentPlan = state.entities[state.selectedEntityId];
    const index = currentPlan.projetMarches.findIndex((o) => o.id === projetMarche.id);
    if (index !== -1) state.entities[state.selectedEntityId].projetMarches[index] = projetMarche;
    else state.entities[state.selectedEntityId].projetMarches.push(projetMarche);
  },

  removeProjetMarcheToPlan(state, projetMarcheId) {
    const currentPlan = state.entities[state.selectedEntityId];
    console.log({ currentPlan });
    state.entities[state.selectedEntityId].projetMarches = currentPlan.projetMarches.filter(
      (o) => o.id !== projetMarcheId
    );
  },
};

const getters = {
  ...helpers.getters,
  ...Appels.getters,

  getCurrentPlan: (state) => {
    const planYears = Object.keys(state.entities).sort((previousYear, nextYear) => nextYear - previousYear);
    const latestYear = planYears[0];
    return state.entities[latestYear];
  },

  getProjets: (state, getters) => getters.getCurrentPlan.projetMarches,

  getOnlyProjets: (state, getters) => getters.getAllEntities.map((plan) => plan.projetMarches),
};

export const ppm = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
