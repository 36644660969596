<template>
  <div class="login-body">
    <div class="login-wrapper">
      <div class="login-image">
        <div class="login-image-content">
          <h1>Bienvenue sur</h1>
          <h1>la plateforme du CNSCL</h1>
          <br />
          <p style="color: #fff; font-size: 22px; font-weight: bold">
            La plateforme du CNSCL est un outil d’intermédiation <br />
            et de dématérialisation des procédures. <br />
            Lieu de publication de tous les appels d’offres <br />
            et opportunités d’emplois. <br />
            Elle permet la mise en relation <br />
            et le suivi, de tous les acteurs <br />
            et investisseurs enrôlés, désirant intervenir comme <br />
            donneurs d’ordres, sous-traitants, <br />
            prestataires de services ou fournisseurs <br />
            dans le secteur pétrolier et gazier.
          </p>
        </div>
      </div>
      <div class="login-panel container">
        <div class="centered-element">
          <img src="assets/layout/images/logocnscl.png" class="logo" alt="diamond-layout" />
          <br /><br />
          <div class="login-form">
            <h2>Mot de passe oublié ?</h2>

            <div v-if="!sended" class="p-field p-col-12 p-float-label width--hundred-per">
              <h6>Renseignez votre adresse email :</h6>
              <InputText
                v-model="v$.form.email.$model"
                id="email"
                type="text"
                class="width--hundred-per"
                :class="{ 'p-invalid': v$.form.email.$invalid && isSubmitted }"
              />
              <p>
                <small v-if="v$.form.email.$invalid && isSubmitted" class="p-error"
                  >L'email renseigné n'est pas valide.
                </small>
              </p>
            </div>
            <div v-if="sended" class="width--hundred-per">
              <h6>Le mot de passe a été envoyé à l'adresse ci-après :</h6>
              <h6>{{ form.email }}</h6>
              <br />
            </div>
            <div v-if="errorEmail" class="width--hundred-per">
              <h6 style="color: red">L'adresse email renseignée n'est pas existante.</h6>
              <br /><br />
            </div>
            <div
              v-if="!sended"
              class="p-grid"
              v-bind:style="{ width: '266px', marginTop: 0, justifyContent: 'space-around' }"
            >
              <router-link to="/login" v-bind:style="{ width: '145px' }">
                <Button label="Retour" type="button" class="p-mr-4 p-mb-2"></Button>
              </router-link>
              <a v-bind:style="{ width: '121px' }">
                <Button
                  @click="eventClick"
                  label="Envoyer"
                  type="button"
                  class="p-mr-4 p-mb-2"
                  :loading="loading"
                ></Button>
              </a>
            </div>
            <div v-if="sended">
              <Button @click="back" label="Retour" type="button" class="p-mb-2"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '../services/http.service';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vee-validate/rules';

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'login',
  data() {
    return {
      sended: false,
      isSubmitted: false,
      loading: false,
      errorEmail: false,
      form: {
        email: '',
      },
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    async eventClick() {
      this.isSubmitted = true;
      this.errorEmail = false;
      this.loading = true;
      const data = {
        email: this.form.email,
      };
      if (this.v$.form.$invalid === false)
        await axios
          .post(baseURL + '/users/forgotPassword', data)
          .then(() => {
            this.sended = true;
            this.loading = true;
          })
          .catch((e) => {
            this.loading = false;
            this.errorEmail = true;
            console.log('res error ' + e);
          });
      else this.loading = false;
    },
    back() {
      this.$router.push('/login');
    },
  },
};
</script>

<style>
.container {
  position: relative;
  height: 400px;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
