<template>
    <DataTable showGridlines :value="refactoList" responsiveLayout="scroll">
        <Column field="title" header="Nom de la formation"></Column>
        <Column field="nbPersons" header="Nombre d'heures"></Column>
        <Column field="nbHours" header="Nombre de sénégalais formés"></Column>
    </DataTable>
</template>
<script>
export default {
  name: 'summaryTrainModel',
  props: ['refactoList'],
};
</script>
