<template>
  <div class="layout-footer">
    <div class="footer-logo-container">
      <span class="app-name">CNSCL</span>
    </div>
    <span class="copyright">&#169; Inclusive IT - {{ new Date().getFullYear() }}</span>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>