<template>
  <!-- {{ refactoList }} -->
    <!-- {{ setRefactoList(questions,answers) }} -->
    <DataTable showGridlines :value="refactoList" responsiveLayout="scroll">
        <Column field="title" header="Titre"></Column>
        <Column field="answers.forecast" header="Valeur">
          <template #body="slotProps">
            {{   `${slotProps.data.answers.forecast}  ${slotProps.data.answers.unit}` }}
          </template>
        </Column>
        <Column field="answers.comment" header="Observations"></Column>
    </DataTable>
</template>
<script>
export default {
  name: 'summaryTableModel',
  props: ['refactoList'],
};
</script>
