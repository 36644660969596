<template>
  <div class="login-body">
    <Toast />

    <div class="login-wrapper">
      <div class="login-image">
        <div class="login-image-content">
          <h1>Bienvenue sur</h1>
          <h1>la plateforme du CNSCL</h1>
          <br />
          <p style="color: #fff; font-size: 22px; font-weight: bold">
            La plateforme du CNSCL est un outil d’intermédiation <br />
            et de dématérialisation des procédures. <br />
            Lieu de publication de tous les appels d’offres <br />
            et opportunités d’emplois. <br />
            Elle permet la mise en relation <br />
            et le suivi, de tous les acteurs <br />
            et investisseurs enrôlés, désirant intervenir comme <br />
            donneurs d’ordres, sous-traitants, <br />
            prestataires de services ou fournisseurs <br />
            dans le secteur pétrolier et gazier.
          </p>
        </div>
      </div>

      <div class="login-panel container">
        <div class="centered-element">
          <img src="assets/layout/images/logocnscl.png" class="logo" alt="diamond-layout" />
          <!--<authentication-form v-on:submitted='onSubmitted' />--->
          <!--<div class="login-form is--centred">-->
          <h2>Accédez à votre compte</h2>
          <br />
          <span class="p-float-label width--hundred-per">
            <InputText
              id="username"
              type="text"
              v-model="email"
              class="width--hundred-per"
              @keyup.enter="submit"
              :class="{ 'p-invalid': isErrorOnEmail, 'width--hundred-per': true }"
              maxlength="40"
            />
            <label>E-mail</label>
          </span>
          <br />
          <div v-if="fc"><br /></div>
          <span class="p-float-label width--hundred-per" v-if="fc">
            <InputText
              type="password"
              v-model="password"
              class="width--hundred-per"
              :class="{ 'p-invalid': isErrorOnPassword, 'width--hundred-per': true }"
              @keyup.enter="submit"
              maxlength="15"
            />
            <label>Mot de passe</label>
          </span>

          <!-- TODO: put to another component -->
          <!-- Change password -->
          <h5 v-if="fc">Veuillez changer votre mot de passe :</h5>
          <br />
          <span class="p-float-label width--hundred-per margin-top--ten-px" v-if="fc">
            <InputText
              type="password"
              v-model="mdp"
              class="width--hundred-per"
              :class="{ 'width--hundred-per': true, 'p-invalid': isErrorOnMdp }"
              @keyup.enter="submit"
              maxlength="15"
              v-tooltip.top="
                'Votre nouveau mot de passe doit être supérieur ou égal à 8 caractères et doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'
              "
            />
            <label for="">Nouveau mot de passe</label>
          </span>
          <span class="p-float-label width--hundred-per" v-if="!fc">
            <InputText
              type="password"
              v-model="mdp"
              class="width--hundred-per"
              :class="{ 'p-invalid': isErrorOnMdp || isErrorOnConnexion }"
              @keyup.enter="submit"
              maxlength="15"
            />
            <label for="">Mot de passe</label>
          </span>
          <span class="p-float-label width--hundred-per margin-top--thirty-px" v-if="fc">
            <InputText
              type="password"
              v-model="confirm_mdp"
              class="width--hundred-per"
              :class="{ 'p-invalid': isErrorOnConfirmMdp || isErrorOnConnexion }"
              @keyup.enter="submit"
              maxlength="15"
            />
            <label for="">Confirmer mot de passe</label>
          </span>

          <!-- TODO: Uniformiser les messages d'erreurs. Soit h4 ou span, ...-->
          <h6 v-if="isErrorOnPassword" style="color: red">Le mot de passe ne correspond pas.</h6>
          <h6 v-if="isErrorOnEmail" style="color: red">L'adresse mail n'est pas valide.</h6>
          <h6 v-if="isErrorOnMdp" style="color: red">
            Votre nouveau mot de passe n'est pas valide. <br />
            Il doit être supérieur ou égal à 8 caractères <br />
            et doit contenir au moins une minuscule, une <br />
            majuscule, un chiffre et un caractère spécial.
          </h6>
          <h6 v-if="isErrorOnConfirmMdp" style="color: red">Vos mots de passe doivent être identiques.</h6>
          <br />
          <div v-if="!fc"><br /></div>
          <div v-if="!fc" class="form__actions width--hundred-per">
            <router-link to="/register" class="p-button p-component p-mb-2 p-mr-4 p-mb-2"> S'inscrire </router-link>
            <Button @click="submit" :loading="loading" label="Se connecter" type="button" class="p-mb-2" />
          </div>
          <div v-if="fc" class="">
            <Button @click="submit" label="  Valider  " type="button" class="p-mb-2"></Button>
          </div>
          <h6 v-if="isErrorOnConnexion" style="color: red">
            Une erreur s'est produite. L'email ou le mot de passe est incorrect.
          </h6>
          <br />
          <p v-if="!fc">Mot de passe oublié ? <router-link to="/firstlogin">Cliquez ici</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode';
import http from '@/services/http.service';

export default {
  name: 'login',
  components: {
    // AuthenticationForm
  },
  data() {
    return {
      user: null,
      id: '',
      value: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      mdp: '',
      confirm_mdp: '',
      role: '',
      fonction: '',
      mobile: '',
      fixe: '',
      entreprise: '',
      all_info_user: false,
      all_info_entreprise: false,
      fc: false,
      checkboxValue: '',
      isErrorOnEmail: false,
      isErrorOnPassword: false,
      isErrorOnMdp: false,
      isErrorOnMdpp: false,
      isErrorOnConfirmMdp: false,
      isErrorOnConnexion: false,
      isError: false,
      access_token: '',
    };
  },
  mounted() {
    // TODO: use cnscl:auth
    if (localStorage.nom) {
      localStorage.removeItem('nom');
    }
    if (localStorage.prenom) {
      localStorage.removeItem('prenom');
    }
    if (localStorage.email) {
      localStorage.removeItem('email');
    }
    if (localStorage.mobile) {
      localStorage.removeItem('mobile');
    }
    if (localStorage.phone) {
      localStorage.removeItem('phone');
    }
    if (localStorage.poste) {
      localStorage.removeItem('poste');
    }
    if (localStorage.entreprise) {
      localStorage.removeItem('entreprise');
    }
    if (localStorage.address) {
      localStorage.removeItem('address');
    }
    if (localStorage.pays) {
      localStorage.removeItem('pays');
    }
    if (localStorage.date) {
      localStorage.removeItem('date');
    }
  },
  methods: {
    isRequired(value) {
      return value ? true : 'This field is required';
    },
    onSubmitted(payload) {
      console.log({ payload });
    },
    async submit() {
      this.isErrorOnEmail = false;
      this.isErrorOnMdp = false;
      this.isErrorOnConfirmMdp = false;
      this.isErrorOnConnexion = false;

      // TODO: remove local data.
      // TODO: Use vue-validate.
      if (this.email.indexOf('@') !== -1 && this.mdp !== '') {
        var data = {
          username: this.email,
          password: this.mdp,
        };
        var passwd = {
          currentPassword: this.password,
          newPassword: this.mdp,
        };

        if (this.fc) {
          if (
            this.mdp.length >= 8 &&
            this.email.indexOf('@') !== -1 &&
            this.mdp !== '' &&
            this.confirm_mdp == this.mdp &&
            this.confirm_mdp !== '' &&
            this.mdp.match(/[0-9]/g) &&
            this.mdp.match(/[A-Z]/g) &&
            this.mdp.match(/[a-z]/g) &&
            this.mdp.match(/[^a-zA-Z\d]/g)
          ) {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            };
            await http.post('/users/changePassword', passwd, config).then(
              (res) => {
                localStorage.setItem('token', this.access_token);
                console.log('***SUCCESS CHANGE PASSWORD CALLBACK*** ' + this.role + res);
                // TODO: set app constant variables.
                if (this.role === 'ROLE_ADMIN') {
                  this.$router.push('/admin');
                } else if (this.role === 'VAL_INSCRIPTION') {
                  this.$router.push('/admin/inscriptions');
                } else if (this.role === 'VAL_PUBLICATION') {
                  this.$router.push('/admin/publications');
                } else if (this.role === 'VAL_PAIEMENT') {
                  this.$router.push('/admin/payment');
                } else {
                  this.$router.push('/');
                }
              },
              (error) => {
                console.log('ERROR ON LOGIN --> ' + error);
                this.isErrorOnConnexion = true;
                this.mdp = '';
              }
            );
          }
        } else {
          await http.post('/users/authToken', data).then(
            (res) => {
              var token = VueJwtDecode.decode(res.data.access_token);
              localStorage.setItem('user', JSON.stringify(token));
              this.access_token = res.data.access_token;
              localStorage.setItem('token', res.data.access_token);
              //var x = JSON.stringify(token);
              this.fc = !token.hasChangePassword;
              this.role = token.roles[0];

              // console.log({ token });

              const hasRole = (roleName) => token.roles.find((role) => role === roleName);
              // console.log(token.roles);

              if (token.hasChangePassword) {
                localStorage.setItem('token', this.access_token);
                if (hasRole('ROLE_ADMIN')) {
                  this.$router.push('/admin');
                } else if (hasRole('VAL_INSCRIPTION')) {
                  this.$router.push('/admin/inscriptions');
                } else if (hasRole('VAL_PUBLICATION')) {
                  this.$router.push('/admin/publications');
                } else if (hasRole('VAL_PAIEMENT')) {
                  this.$router.push('/admin/payment');
                } else {
                  // console.log('FIFDSFA');
                  this.$router.push('/');
                }
              } else {
                // console.log('fdsfdsa ', { token });
                this.password = this.mdp;
                this.mdp = '';
                this.loading = false;
                this.isErrorOnMdp = false;
                this.isErrorOnConnexion = false;
                this.isErrorOnConfirmMdp = false;
              }
            },
            (error) => {
              console.log('ERROR ON LOGIN CHANGE PASSWD --> ' + error);
              this.isErrorOnConnexion = true;
              this.mdp = '';
            }
          );
        }
      }
      if (this.email.indexOf('@') === -1) {
        this.isErrorOnEmail = true;
        // console.log('EMAIL MUST CONTAINS @ and . => email:' + this.email + ' password:' + this.mdp);
      } else {
        this.isErrorOnEmail = false;
      }
      /*
      // TODO: veeValidate.
      if (this.mdp.length < 3 && this.confirm_mdp) {
        this.isErrorOnMdp = true;
        console.log('PASSWORD TOO SHORT !!! =>' + this.mdp);
      } else {
        this.isErrorOnMdp = false;
      }
      if (this.confirm_mdp !== this.mdp) {
        this.isErrorOnConfirmMdp = true;
        this.confirm_mdp = '';
      } else {
        this.isErrorOnConfirmMdp = false;
      }
      */
      if (this.mdp.length < 8 && this.mdp.length != 0 && this.fc) {
        this.isErrorOnMdp = true;
        // console.log('PASSWORD TOO SHORT !!! =>' + this.mdp);
      } else if (
        !(
          this.mdp.match(/[0-9]/g) &&
          this.mdp.match(/[A-Z]/g) &&
          this.mdp.match(/[a-z]/g) &&
          this.mdp.match(/[^a-zA-Z\d]/g)
        ) &&
        this.mdp.length != 0 &&
        this.fc
      ) {
        this.isErrorOnMdp = true;
        // console.log('Mot de passe faible !!! =>' + this.mdp);
      } else if (this.confirm_mdp !== this.mdp && this.fc) {
        this.isErrorOnConfirmMdp = true;
        this.confirm_mdp = '';
      }
    },
  },
};
</script>

<style>
.is--centred {
  margin: auto;
  padding: 10px;
}
.margin-top--ten-px {
  margin-top: 10px !important;
}
.margin-top--thirty-px {
  margin-top: 30px !important;
}
.width--hundred-per {
  width: 100% !important;
}

.form__actions {
  display: flex;
  flex-direction: row;
}
.form__actions > *:last-child {
  margin-left: auto;
}
.container {
  position: relative;
  height: 400px;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
