const LoggedUserMixin = {
  data: function () {
    return {
      user: {},
    };
  },

  methods: {
    getLoggedUser: () => {
      return JSON.parse(localStorage.getItem('user'));
    },

    isDonneurOrdre: () => {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles.find((role) => role.name.includes('USER_DO'));
    },

    hasSubscribedForCurrentYearPlan: (selectedYear) => {
      // console.log({ currentYear, 'new Date()': new Date().getFullYear() });
      // TODO: To remove later !!!!!!
      const currentYear = new Date().getFullYear();
      return currentYear - 1 <= selectedYear && selectedYear <= currentYear;
    },

    /**
     * Retourne le dernier abonnement de l'utilisateur connecté.
     * @param {User} user
     * @returns void
     */
    getCurrentSubscription(user) {
      //const subscriptions = user?.entreprise?.abonnements;
      // const idx = subscriptions?.length - 1;
      const idx = 0;
      return user?.entreprise?.abonnements[idx];
    },

    isAdminRole(){
      const user = JSON.parse(localStorage.getItem('user'));
      let select = user.roles.filter((role) => role.name === 'ROLE_ADMIN');
      // console.log("sss",select.length);
      if(select.length > 0) return true;
      return false;
    },
    isSimpleAdminRole(){
      const user = JSON.parse(localStorage.getItem('user'));
      let select = user.roles.filter((role) => role.name === 'ROLE_SIMPLE_ADMIN');
      if(select.length > 0) return true;

      return false;
    },
    isValidatorPaymentRole(){
      const user = JSON.parse(localStorage.getItem('user'));
      let select = user.roles.filter((role) => role.name === 'VAL_PAIEMENT');
      if(select.length > 0) return true;

      return false;
    },
    isValidatorInscriptionRole(){
      const user = JSON.parse(localStorage.getItem('user'));
      let select = user.roles.filter((role) => role.name === 'VAL_INSCRIPTION');
      if(select.length > 0) return true;

      return false;
    },
    isValidatorPublicationRole(){
      const user = JSON.parse(localStorage.getItem('user'));
      let select = user.roles.filter((role) => role.name === 'VAL_PUBLICATION');
      if(select.length > 0) return true;

      return false;
    },

    isSubscribing(subscription) {
      return ['CREATED', 'INITIE', 'ENTERPRISE_INITIATED'].includes(subscription?.statut);
    },
    isPaying(subscription) {
      return ['APPROUVE', 'POSTE', 'PAIEMENT_REJETE'].includes(subscription?.statut);
    },
    hasNotStarted(subscription) {
      return subscription?.statut === 'CREATED';
    },
    isFillingUserForm(subscription) {
      return subscription?.statut === 'INITIE';
    },
    isFillingEnterpriseForm(subscription) {
      return subscription?.statut === 'ENTERPRISE_INITIATED';
    },
    isSubscriptionRejected(subscription) {
      return subscription?.statut === 'REJETE';
    },
    isSubscriptionValidated(subscription) {
      return subscription?.statut === 'APPROUVE';
    },
    isWaitingSubscriptionValidate(subscription) {
      return subscription?.statut === 'EN_COURS';
    },
    isFillingPaymentForm(subscription) {
      return subscription?.statut === 'APPROUVE';
    },
    isPaymentSubmitted(subscription) {
      return ['POSTE', 'TRIAL_PAYMENT_PENDING'].includes(subscription?.statut);
    },
    isPaymentRejected(subscription) {
      return subscription?.statut === 'PAIEMENT_REJETE';
    },
    isPaymentValidated(subscription) {
      return subscription?.statut === 'PAIEMENT_APPROUVE';
    },
    isSuscriptionTrial(subscription) {
      return subscription?.statut === 'TRIAL';
    },
    isSuscriptionSuspended(subscription) {
      return subscription?.statut === 'ECHU';
    },
    isTrialPaymentPending(subscription) {
      return subscription?.statut === 'TRIAL_PAYMENT_PENDING';
    },

    // CHECK IF USER IS CURRENTLY SUSPENDED
    isInGracePeriod(subscriptions) {
      //
      const lastSubscription = subscriptions[subscriptions.length - 1];

      if (this.isSuscriptionSuspended(lastSubscription)) {
        return false;
      }

      const isReSubscribing = ['TRIAL_PAYMENT_PENDING', 'REJETE', 'POSTE', 'TRIAL'].includes(lastSubscription);

      if (isReSubscribing) {
        return !this.isSuscriptionSuspended(subscriptions[subscriptions.length - 2]);
      }

      return false;
    },
  },
};

export default LoggedUserMixin;
