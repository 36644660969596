import httpService from "@/services/http.service"
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';

const initialState = {
  isLoading: false,
  isEditing: false,
  isSubmitting: false,
  isLoaded: false,
  countPlans: 0,
  countAppelsOffres: 0,
  countAppelsManifestations: 0
}

const actions = {
  fetchStats({ commit }, payload){
    commit("setIsLoading", true);

    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/stats", q)).then((response) => {
      commit("setStats", response.data);
      commit("setIsLoading", false);
    })
  },
};

const mutations = {
  ...helpers.mutations,
  setStats(state, data) {
    state.countAppelsManifestations = data.countAmis;
    state.countAppelsOffres = data.countAppelsOffres;
    state.countPlans = data.countPlans;
    state.countProjets = data.countProjets;
  }
};

const getters = {
  ...helpers.getters,
  getStats: (state) => {
    return {
      countAppelsManifestations: state.countAppelsManifestations,
      countAppelsOffres: state.countAppelsOffres,
      countPlans: state.countPlans,
      countProjets: state.countProjets,
    }
  }
};

export const stats = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
}