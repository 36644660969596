<template>
  <div class="login-body">
    <Toast position="top-right" />
    <div class="login-wrapper">
      <div class="login-image">
        <div class="login-image-content">
          <h1>Bienvenue sur</h1>
          <h1>la plateforme du CNSCL</h1>
          <br />
          <p style="color: #fff; font-size: 22px; font-weight: bold">
            La plateforme du CNSCL est un outil d’intermédiation <br />
            et de dématérialisation des procédures. <br />
            Lieu de publication de tous les appels d’offres <br />
            et opportunités d’emplois. <br />
            Elle permet la mise en relation <br />
            et le suivi, de tous les acteurs <br />
            et investisseurs enrôlés, désirant intervenir comme <br />
            donneurs d’ordres, sous-traitants, <br />
            prestataires de services ou fournisseurs <br />
            dans le secteur pétrolier et gazier.
          </p>
        </div>
      </div>
      <div class="login-panel container">
        <div class="centered-element">
          <img src="assets/layout/images/logocnscl.png" class="logo" alt="diamond-layout" />

          <h2>S'inscrire</h2>
          <br /><br />
          <span class="p-float-label width--hundred-per">
            <InputText
              id="id-lastName"
              type="text"
              v-model="v$.form.lastName.$model"
              class="width--hundred-per"
              v-bind:style="{ width: '250px' }"
              :class="{ 'width--hundred-per': true, 'p-invalid': hasErrors('lastName') }"
            />
            <label for="id-lastName">Nom</label>
          </span>
          <br /><br />
          <span class="p-float-label width--hundred-per">
            <InputText
              id="id-firstName"
              type="text"
              v-bind:style="{ width: '250px' }"
              v-model="v$.form.firstName.$model"
              class="width--hundred-per"
              :class="{ 'width--hundred-per': true, 'p-invalid': hasErrors('firstName') }"
            />
            <label for="id-firstName">Prénom</label>
          </span>
          <br /><br />
          <span class="p-float-label width--hundred-per">
            <InputText
              id="id-email"
              type="email"
              v-bind:style="{ width: '250px' }"
              v-model="v$.form.email.$model"
              class="width--hundred-per"
              :class="{ 'width--hundred-per': true, 'p-invalid': hasErrors('email') }"
            />
            <label for="id-email">E-mail</label>
          </span>
          <br /><br />
          <span class="p-float-label width--hundred-per">
            <InputText
              id="id-enterpriseName"
              type="text"
              v-bind:style="{ width: '250px' }"
              v-model="v$.form.entrepriseName.$model"
              class="width--hundred-per"
              :class="{ 'width--hundred-per': true, 'p-invalid': hasErrors('entrepriseName') }"
              maxlength="25"
            />
            <label for="id-enterpriseName">Entreprise</label>
          </span>
          <br /><br />
          <Button v-on:click="onSubmit" label="Valider l'inscription" type="button" class="width--hundred-per"></Button>
          <br /><br />
          <p>
            Vous avez déjà un compte ?
            <router-link to="/login">Connexion</router-link>
          </p>
        </div>

        <Dialog
          v-model:visible="confirmationDialogState"
          :style="{ width: '450px', fontWeight: 'bold' }"
          header="Inscription au CNSCL"
          :modal="true"
          class="p-fluid"
        >
          <h3></h3>
          <h5>Votre compte a bien été créé.</h5>
          <h5>Vous recevrez dans un instant un e-mail contenant vos informations de connexion.</h5>
          <h3></h3>

          <template #footer>
            <Button
              label="Se connecter"
              icon="pi pi-check"
              class="p-button p-button-outlined"
              @click="redirectToLogin"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '../services/http-v2.service';
import { BackendUrls } from '../services/urlsConfigs';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'register',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      confirmationDialogState: false,
      submitted: false,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        entrepriseName: null,
      },
    };
  },

  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      entrepriseName: {
        required,
        minLength: minLength(2),
      },
    },
  },

  methods: {
    onSubmit() {
      this.submitted = true;
      if (this.v$.form.$invalid) {
        return;
      }
      Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      Http.post(BackendUrls.RegistrationUrl(), { ...this.form })
        .then(this.handleRegistrationSucceed)
        .catch(this.handleRegistrationFailed);
    },

    redirectToLogin() {
      this.$router.push('/login');
    },

    handleRegistrationSucceed() {
      this.confirmationDialogState = true;
    },

    handleRegistrationFailed(error) {
      this.$toast.add({
        severity: 'error',
        summary: "Echec de l'inscription.",
        detail:
          error?.response?.data?.message ||
          'Une erreur est survenue, veuillez recommencer ou contacter votre administrateur.',
        life: 7000,
      });
    },

    hasErrors(name) {
      const hasMinLenError = this.v$.form[name].minLength?.$invalid;
      const hasRequiredError = this.v$.form[name].required?.$invalid;
      const hasEmailError = this.v$.form[name].email?.$invalid;

      return this.submitted && (hasMinLenError || hasRequiredError || hasEmailError);
    },
  },
};
</script>

<style>
.container {
  position: relative;
  height: 400px;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
