import httpService from "@/services/http.service"
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';

const initialState = {
  isEditing: false,
  isLoading: false,
  isLoaded: false,
  isSubmitting: "NO",
  isDeleting: false,
  entities: {},
  links: {},
  meta: {},
  errors: {
    validation: {}
  },
  selectedEntityId: null
};

const actions = {
  fetchAll({ commit }, payload) {
    commit("setIsLoading", true);
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/countries", q))
      .then((response) => {
        commit("setEntities", { entities: response.data });
        commit("setIsLoading", false);
      })
  },

  createOrUpdate({ commit }, { data }) {
    commit("setIsSubmitting", SUBMIT_REQUESTED);
    const url = data.id === undefined
      ? '/countries'
      : `/countries/${data.id}`;
    const promise = data.id === undefined ? httpService.post(url, data) : httpService.put(url, data);

    promise.then((response) => {
      const entity = response.data;
      commit("setEntity", { entity })
      commit("setIsEditing", false);
      commit("setIsSubmitting", SUBMIT_SUCCEED);
    }).catch(() => {
      commit("setIsSubmitting", SUBMIT_FAILED);
    })
  },

  delete({ commit }, { id }) {
    commit("setIsDeleting", true);
    httpService.delete(`/countries/${id}`).then(() => {
      commit("unsetEntity", { attr: id });
      commit("setIsDeleting", false);
      // TODO: TO correct !
    });

  },
}

const mutations = {
  ...helpers.mutations,
}

const getters = {
  ...helpers.getters,
}

export const pays = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
}