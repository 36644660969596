<template>
  <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
    <div class="layout-content-wrapper">
      <AppTopBar
        :topbarNotificationMenuActive="topbarNotificationMenuActive"
        :topbarUserMenuActive="topbarUserMenuActive"
        :layoutMode="layoutMode"
        :menu="menu"
        :menuActive="menuActive"
        :staticMenuMobileActive="staticMenuMobileActive"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      ></AppTopBar>
      <ConfirmDialog></ConfirmDialog>
      <Dialog
        :closable="false"
        :modal="true"
        header="Confirmation du réabonnement"
        :maximizable="true"
        v-model:visible="isRevokeDialogsOpened"
        :breakpoints="{ '960px': '80vw', '640px': '100vw' }"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-dialog-titlebar">
            <a href="https://www.cnscl.sn/" class="flex align-items-center justify-content-center">
              <img width="120" src="assets/layout/images/logocnscl.png" alt="CNSCL" />
            </a>
            <h4 class="p-ml-3 p-dialog-title">Suspension</h4>
          </div>
        </template>
        <section class="p-dialog-content">
          <div class="text-xl">
            Êtes vous sûr de vouloir suspendre tous les comptes dont le délai de réabonnement est dépassé ? <br />
            ( Période: <b>{{ new Date().getFullYear() }}</b> ) <br />
            <b style="color: red">Attention, cette action est irreversible !</b>
          </div>
        </section>
        <template #footer>
          <Button label="Annuler" icon="pi pi-times" class="p-button-text" @click="onSuspendToggle" />
          <Button
            :loading="isSubmitting === 'SUBMIT_REQUESTED'"
            @click="handleRevokeSubscriptions"
            label="Procéder à la suspension"
            icon="pi pi-check"
            class="p-button"
            autofocus
          />
        </template>
      </Dialog>

      <Dialog
        :closable="false"
        :modal="true"
        header="Résumè du plan de contenu local"
        :maximizable="true"
        v-model:visible="isPlanSummaryDialogsOpened"
        :breakpoints="{ '960px': '100vw', '640px': '80vw' }"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-dialog-titlebar">
            <a href="https://www.cnscl.sn/" class="flex align-items-center justify-content-center">
              <img width="120" src="assets/layout/images/logocnscl.png" alt="CNSCL" />
            </a>
            <h4 class="p-ml-3 p-dialog-title">Résumé plan de contenu local - 2023</h4>
          </div>
        </template>
        <section class="p-dialog-content">
          <!-- {{ this.getTrainingsInTable }} -->
          <!-- {{ getPlanSummary?.questions }} -->
          <!-- {{ getPlanSummary?.questions.filter(e=>{return e.heading === 'JOBS'})}} -->
          <div class="p-fluid p-formgrid p-grid">
            <Accordion :style="{ width: '90em' }">
              <AccordionTab header="Emploi">
                <summary-data-table
                  :refactoList="this.jobsList"
                ></summary-data-table>
              </AccordionTab>
              <AccordionTab header="Plan de succession - Salaires">
                <summary-data-table
                  :refactoList="this.salaryList"
                ></summary-data-table>
              </AccordionTab>
              <AccordionTab header="Formation">
                <summary-train-table
                  :refactoList="this.getTrainingsInTable"
                ></summary-train-table>
                <summary-data-table
                  :refactoList="this.trainingList"
                ></summary-data-table> 
              </AccordionTab>
              <AccordionTab header="Approvisionnement en biens - travaux et services">
                <summary-data-table
                  :refactoList="this.supplyList"
                ></summary-data-table>
              </AccordionTab>
            </Accordion>
          </div>
        </section>
        <template #footer>
          <Button label="Annuler" icon="pi pi-times" class="p-button-text" @click="this.loadPlanSummary()" />
          <Button
            @click="submitPlanToValidator()"
            label="Soumettre le plan de contenu local"
            icon="pi pi-check"
            class="p-button"
            autofocus
          />
        </template>
      </Dialog>

      <div class="layout-content">
        <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
          <template v-if="$route.meta.breadcrumb">
            <span>{{ $route.meta.breadcrumb[0].label }}</span>
          </template>
        </div>
        <router-view />
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import LoggedUserMixin from '@/mixins/logged-user';
import summaryDataTable from './components/local-content/component/summary-data-table.vue';
import summaryTrainTable from './components/local-content/component/summary-train-table.vue';
import http from './services/http.service';
import { mapActions, mapGetters } from 'vuex';

const MENU = [];

export default {
  mixins: [LoggedUserMixin],
  data() {
    return {
      jobsList:[],
      salaryList:[],
      supplyList:[],
      trainingList:[],
      isVisible: true,
      menuActive: false,
      layoutMode: 'slim',
      colorScheme: 'light',
      menuTheme: 'layout-sidebar-white',
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      userMenuClick: false,
      topbarUserMenuActive: false,
      notificationMenuClick: false,
      topbarNotificationMenuActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      configActive: false,
      configClick: false,
      menu: [],
      token: '',
      role: '',
      user: null,
    };
  },
  async mounted() {
    this.token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };

    // TODO: control sur last abonnement should be PAIEMENT_APPROUVE or TRIAL
    // TODO: Se basser sur LoggedUserMixin.
    try {
      // TODO: Remove get profile from here.
      const res = await http.get('/users/getProfile', config);
      //collect it
      this.user = res.data;
      localStorage.setItem('user', JSON.stringify(res.data));

      //INITIALIZE MENU ARRAY TO EMPTY

      // IF USER HAS COMPANY
      if (this.user?.entreprise) {
        this.menu = [
          {
            label: 'Accueil',
            icon: 'pi pi-fw pi-home',
            to: '/',
          },
          { separator: true },
        ];

        // PROCESS FOR USER WITH COMPANY
        const company = this.user.entreprise;
        const rang_souscription_en_cours = company.rangSouscriptionEnCours;
        // const last_index = company.abonnements.length - 1;
        const last_index = 0;
        const subscriptionStatus = company.abonnements[last_index]?.statut;

        //VERIFY IF USER STATE IS  PAIEMENT_APPROUVE OU PAIEMENT_REJETEE OU POSTE
        if (['CREATED', 'INITIE', 'ENTERPRISE_INITIATED', 'REJETE'].includes(subscriptionStatus)) {
          return;
        }
        if (
          subscriptionStatus === 'EN_COURS' ||
          subscriptionStatus === 'APPROUVE' ||
          subscriptionStatus === 'PAIEMENT_REJETE' ||
          rang_souscription_en_cours === 'LOCAL' ||
          subscriptionStatus === 'POSTE'
        ) {
          // ADD TO MENU ITEM FOR HOME PROFIL COMPANY
          this.menu.push(
            {
              label: 'Profil',
              icon: 'pi pi-fw pi-id-card',
              to: '/profile',
            },
            { separator: true },
            {
              label: 'Entreprise',
              icon: 'pi pi-fw pi-briefcase',
              to: '/company',
            },
            { separator: true }
          );
          if (rang_souscription_en_cours !== 'LOCAL') return;
        }
        //IS MAJOR RANK_UN ou RANK_DEUX PPM etc... EXCEPT PUBLICATIONS

        if (
          rang_souscription_en_cours === 'MAJOR' ||
          rang_souscription_en_cours === 'RANK_UN' ||
          rang_souscription_en_cours === 'RANK_DEUX'
        ) {
          // this.isSuspendedMode() &&
          this.menu.push(
            {
              label: 'Profil',
              icon: 'pi pi-fw pi-id-card',
              to: '/profile',
            },
            { separator: true },
            {
              label: 'Entreprise',
              icon: 'pi pi-fw pi-briefcase',
              to: '/company',
            },
            { separator: true },
            {
              label: 'Mon portail',
              icon: 'pi pi-fw pi-globe',
              to: '#',
            },
            { separator: true },
            {
              label: 'PPM',
              icon: 'pi pi-fw pi-folder-open',
              to: '/home',
            },
            { separator: true },
            {
              label: "Appel d'offre",
              icon: 'pi pi-fw pi-folder',
              to: '/home/appels-offre',
            },
            { separator: true },
            {
              label: 'AMI',
              icon: 'pi pi-fw pi-folder',
              to: '/home/ami',
            },
            { separator: true },
            {
              label: 'Plan de contenu local',
              icon: 'pi pi-fw pi-book',
              to: `/local-content/step/${this.getLoggedUser().entreprise.name}/`,
            },
            { separator: true },
            {
              label: 'Publications',
              icon: 'pi pi-fw pi-list',
              to: '/home/prestataires',
            }
          );
        }

        //IS LOCAL DROIT_SENEGALAIS ETRANGER
        if (
          rang_souscription_en_cours === 'DROIT_SENEGALAIS' ||
          rang_souscription_en_cours === 'LOCAL' ||
          rang_souscription_en_cours === 'ETRANGER'
        ) {
          // this.isSuspendedMode() &&
          this.menu.push({
            label: 'Publications',
            icon: 'pi pi-fw pi-list',
            to: '/home/prestataires',
          });
        }
        return;
      } else {
        //PROCESS FOR ADMIN
        const hasAdminRole = this.user.roles.find((role) => role.name === 'ROLE_ADMIN') !== undefined;
        const hasSimpleAdminRole = this.user.roles.find((role) => role.name === 'ROLE_SIMPLE_ADMIN') !== undefined;
        const hasValidatorPaymentRole = this.user.roles.find((role) => role.name === 'VAL_PAIEMENT') !== undefined;
        const hasValidatorInscriptionRole =
          this.user.roles.find((role) => role.name === 'VAL_INSCRIPTION') !== undefined;
        const hasValidatorPublicationRole =
          this.user.roles.find((role) => role.name === 'VAL_PUBLICATION') !== undefined;

        this.menu = [];

        if (!hasAdminRole || hasSimpleAdminRole) this.menu = MENU;

        if ((hasAdminRole || hasSimpleAdminRole) && !this.exitsInMenu('Utilisateurs'))
          this.menu.push({
            label: 'Utilisateurs',
            icon: 'pi pi-fw pi-users',
            to: '/admin',
          });

        if ((hasValidatorInscriptionRole || hasAdminRole || hasSimpleAdminRole) && !this.exitsInMenu('Inscriptions'))
          this.menu.push(
            ...[
              { separator: true },
              {
                label: 'Inscriptions',
                icon: 'pi pi-fw pi-id-card',
                to: '/admin/inscriptions',
              },
            ]
          );

        if (
          (hasValidatorPublicationRole || hasAdminRole || hasSimpleAdminRole) &&
          !this.exitsInMenu('Plan de contenu local')
        )
          this.menu.push(
            ...[
              { separator: true },
              {
                label: 'Plan de contenu local',
                icon: 'pi pi-fw pi-book',
                to: '/local-content',
              },
            ]
          );

        if ((hasValidatorPaymentRole || hasAdminRole || hasSimpleAdminRole) && !this.exitsInMenu('Paiements')) {
          this.menu.push(
            ...[
              { separator: true },
              {
                label: 'Paiements',
                icon: 'pi pi-fw pi-dollar',
                to: '/admin/payment',
              },
            ]
          );
        }

        if ((hasValidatorPublicationRole || hasAdminRole || hasSimpleAdminRole) && !this.exitsInMenu('Publications'))
          this.menu.push(
            ...[
              { separator: true },
              {
                label: 'Publications',
                icon: 'pi pi-fw pi-folder-open',
                items: [
                  {
                    label: ' Plan de passation de marché',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/publications',
                  },
                  {
                    label: " Appel d'offre",
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/publications/appels-offre',
                  },
                  {
                    label: " Appel à manisfestatiion d'intérêt",
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/publications/ami',
                  },
                ],
              },
            ]
          );

        if (hasAdminRole && !this.exitsInMenu('Généralités'))
          this.menu.push(
            ...[
              { separator: true },
              {
                label: 'Généralités',
                icon: 'pi pi-fw pi-list',
                items: [
                  {
                    label: 'Catégorie',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/categories',
                  },
                  {
                    label: 'Domaines métiers',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/domaines-metiers',
                  },
                  {
                    label: 'Pays',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/pays',
                  },
                  {
                    label: 'Nature de prestation',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/nature-de-prestation',
                  },
                  {
                    label: 'Montant / Coût des abonnements',
                    icon: 'pi pi-fw pi-align-left',
                    to: '/admin/tarifs',
                  },
                ],
              },
            ]
          );

        if (
          !hasAdminRole &&
          !hasSimpleAdminRole &&
          !hasValidatorPaymentRole &&
          !hasValidatorInscriptionRole &&
          !hasValidatorPublicationRole &&
          !this.exitsInMenu('Accueil')
        ) {
          this.menu = [
            {
              label: 'Accueil',
              icon: 'pi pi-fw pi-home',
              to: '/',
            },
            { separator: true },
          ];
        }
      }
    } catch (e) {
      console.log('APP_ERROR = ', e);
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapGetters({
      getTrainingsInTable: 'localContent/getTrainingsInTable',
      isRevokeDialogsOpened: 'dialog/getRevokeDialogStatus',
      isPlanSummaryDialogsOpened: 'dialog/isPlanSummaryDialogsOpened',
      isSubmitting: 'subscriptions/getIsSubmitting',
      getPlanSummary: 'localContent/getPlanSummary',
    }),
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-slim': this.layoutMode === 'slim',
          'layout-horizontal': this.layoutMode === 'horizontal',
          'layout-sidebar-dim': this.colorScheme === 'dim',
          'layout-sidebar-dark': this.colorScheme === 'dark',
          'layout-overlay-active': this.overlayMenuActive,
          'layout-mobile-active': this.staticMenuMobileActive,
          'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': !this.$primevue.config.ripple,
        },
        this.colorScheme === 'light' ? this.menuTheme : '',
      ];
    },
  },
  components: {
    AppTopBar,
    AppFooter,
    summaryDataTable,
    summaryTrainTable
  },
  watch: {
    getPlanSummary: {
        handler(val) {
            if (val) {
                this.setRefactSummary(val);
            }
        },
        deep: true
    },
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    ...mapActions({
      onSuspendToggle: 'dialog/revokeAccounts',
      loadPlanSummary: 'dialog/loadPlanSummary',
      revokeSubscriptions: 'subscriptions/revoke',
      updateStatus: 'localContent/updateStatus',
    }),
    setRefactSummary(val){
      let questions = val.questions;
      let answers = val.answers;
      questions.forEach((element) => {
        let hasAnswer = [];
        hasAnswer = answers.filter((e) => {
          return e.questionId === element.id;
        })?.[0];

        if(element.heading === 'JOBS'){
          this.jobsList.push({
            answers: {
              unit:element.unit,
              ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
              questionId: element.id,
              ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
              forecast: hasAnswer?.forecast >= 0 ? hasAnswer?.forecast : null,
              real: hasAnswer?.real >= 0 ? hasAnswer?.real : null,
              comment: hasAnswer?.comment || '',
            },
            title: element.title,
            year: 2023,
          });
        }

        if(element.heading === 'SALARIES'){
          
          this.salaryList.push({
            answers: {
              unit:element.unit,
              ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
              questionId: element.id,
              ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
              forecast: hasAnswer?.forecast >= 0 ? hasAnswer?.forecast : null,
              real: hasAnswer?.real >= 0 ? hasAnswer?.real : null,
              comment: hasAnswer?.comment || '',
            },
            title: element.title,
            year: 2023,
          });
        }

        if(element.heading === 'SUPPLY'){
          
          this.supplyList.push({
            answers: {
              unit:element.unit,
              ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
              questionId: element.id,
              ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
              forecast: hasAnswer?.forecast >= 0 ? hasAnswer?.forecast : null,
              real: hasAnswer?.real >= 0 ? hasAnswer?.real : null,
              comment: hasAnswer?.comment || '',
            },
            title: element.title,
            year: 2023,
          });
        }

        if(element.heading === 'TRAININGS'){
          
          this.trainingList.push({
            answers: {
              unit:element.unit,
              ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
              questionId: element.id,
              ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
              forecast: hasAnswer?.forecast >= 0 ? hasAnswer?.forecast : null,
              real: hasAnswer?.real >= 0 ? hasAnswer?.real : null,
              comment: hasAnswer?.comment || '',
            },
            title: element.title,
            year: 2023,
          });
        }

      });
    },
    submitPlanToValidator() {
      const localContentPlanId = this.getPlanSummary.answers[0].localContentPlanId;
      let Obj = {
        answers: [],
        event: 'SUBMIT',
      };
      // {
      //   "event": "CREATE",
      //   "answers": [
      //     {
      //       "id": 0,
      //       "validityStatus": "F_VALID",
      //       "comment": "string"
      //     }
      //   ]
      // }
      this.$confirm.require({
        message: 'Voulez-vous soumettre le plan de contenu locale ?',
        header: 'Confirmation de soumission',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        acceptClass: 'p-button-danger',
        accept: () => {
          this.updateStatus({ data: Obj, lcpId: localContentPlanId })
        .then((e) => {
          this.loadPlanSummary();
          this.$toast.add({
            severity: 'success',
            summary: 'Soumisson du plan de contenu local',
            detail: 'Le plan de contenu local a été soumis avec success pour validation',
            life: 7000,
          });
          console.log(e);
          this.$router.push(this.$router.currentRoute._value.meta.breadcrumb[0]?.to);
        })
        .catch((e) => {
          this.loadPlanSummary();
          this.$toast.add({
            severity: 'success',
            summary: 'Soumisson du plan de contenu local',
            detail: 'Le plan de contenu local a été soumis avec success pour validation',
            life: 7000,
          });
          console.log(e);
        });
    
        },
        reject: () => {
          // this.$toast.add({severity:'info', summary:'Operation annul', detail:'You have rejected', life: 3000});
        },
      });
    
    },
    isSuspendedMode() {
      // console.log(this.getLoggedUser());
      const last_index = this.getLoggedUser().entreprise?.abonnements.length - 1;
      const lastSubscription = this.getLoggedUser().entreprise?.abonnements[last_index];
      return !this.isSuscriptionSuspended(lastSubscription);
    },
    handleRevokeSubscriptions() {
      this.revokeSubscriptions({ year: new Date().getFullYear() });
    },
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim() || this.isHorizontal()) {
          EventBus.emit('reset-active-index');
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick() {
      this.searchClick = true;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onRightMenuButtonClick(event) {
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll');
      } else {
        document.body.className += ' blocked-scroll';
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll');
      } else {
        document.body.className = document.body.className.replace(
          new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' '
        );
      }
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    isOverlay() {
      return this.layoutMode === 'overlay';
    },
    isDesktop() {
      return window.innerWidth > 1091;
    },
    exitsInMenu(label) {
      return this.menu.find((item) => item.label === label) !== undefined;
    },
  },
};
</script>

<style lang="scss">
@import 'App.scss';
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/* container */
.responsive-three-column-grid {
  display: block;
}

/* columns */
.responsive-three-column-grid > * {
  padding: 1rem;
}

/* tablet breakpoint */
@media (min-width: 768px) {
  .responsive-three-column-grid {
    display: grid;
    gap: 1em;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.truncate-custom {
  text-overflow: ellipsis;
  overflow: hidden;
}
.greenBg{

}
.redBg{
  
}
</style>
