import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    exact: true,
    component: () => import('./components/Dashboard.vue'),
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Bienvenue sur la plateforme du CNSCL' }],
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('./pages/DashboardAdmin.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  {
    path: '/admin/payment',
    name: 'adminPayment',
    component: () => import('./pages/DashboardPayment.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  {
    path: '/admin/inscriptions',
    name: 'adminInscription',
    component: () => import('./pages/DashboardSubscriptions.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  {
    path: '/admin/publications',
    name: 'adminPub',
    component: () => import('./pages/DashboardPub.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  {
    path: '/admin/publications/appels-offre',
    name: 'adminPubAO',
    component: () => import('./pages/DashboardAO.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  {
    path: '/admin/publications/ami',
    name: 'adminPubAMI',
    component: () => import('./pages/DashboardAMI.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme d'administration du ST CNSCL" }],
    },
  },
  // T
  {
    path: '/admin/categories',
    name: 'AdminCategories',
    component: () => import('./pages/GeneralPage.vue'),
    meta: {
      namespace: "categories",
      fnFetchName: "fetchAllCategories",
      dependsOn: [],
      extras: [],
      item: {
        name: null,
        libelle_en: null,
        code: null,
      },
      components: {
        formComponent: 'form-item',
        listComponent: 'categorie-list',
      },
      breadcrumb: [{ parent: 'Administration', label: "Catégories" }],
    },
  },
  {
    path: '/admin/domaines-metiers',
    name: 'AdminDomainesMetiers',
    component: () => import('./pages/GeneralPage.vue'),
    meta: {
      namespace: 'domainesMetiers',
      fnFetchName: "fetchAllDomainesMetiers",
      dependsOn: ["fetchAllCategories"],
      extras: ["categories"],
      item: {
        libelle: null,
        code: null,
        libelle_en: null,
        regime: null,
        regimeCinqAns: null,
        parentId: null,
        categoryId: null,
      },
      components: {
        formComponent: 'domaine-metier-form',
        listComponent: 'domaine-metier-list',
      },
      breadcrumb: [{ parent: 'Administration', label: "Domaines métiers" }],
    },
  },
  {
    path: '/admin/pays',
    name: 'AdminPays',
    component: () => import('./pages/GeneralPage.vue'),
    meta: {
      namespace: "pays",
      fnFetchName: "fetchAllPays",
      dependsOn: [],
      extras: [],
      item: {
        name: null,
        libelle_en: null,
        code: null,
      },
      components: {
        formComponent: 'pays-form',
        listComponent: 'pays-list',
      },
      breadcrumb: [{ parent: 'Administration', label: "Pays" }],
    },
  },
  {
    path: '/admin/nature-de-prestation',
    name: 'AdminNaturePrestations',
    component: () => import('./pages/GeneralPage.vue'),
    meta: {
      namespace: "naturePrestation",
      fnFetchName: "fetchAllNaturePrestation",
      dependsOn: [],
      extras: [],
      item: {
        libelle: null,
        code: null,
      },
      components: {
        formComponent: 'nature-prestation-form',
        listComponent: 'nature-prestation-list',
      },
      breadcrumb: [{ parent: 'Administration', label: "Nature de prestation" }],
    },
  },
  {
    path: '/admin/tarifs',
    name: 'AdminTarifs',
    component: () => import('./pages/GeneralPage.vue'),
    meta: {
      namespace: "tarifs",
      fnFetchName: "fetchAllTarifs",
      dependsOn: [],
      extras: [],
      item: {
        adherant: null,
        rang: null,
        montant_xof: null,
        montant_dollar_us: null,
      },
      components: {
        formComponent: 'tarif-form',
        listComponent: 'tarif-list',
      },
      breadcrumb: [{ parent: 'Administration', label: "Tarifs" }],
    },
  },
  // T
  {
    path: '/home',
    name: 'home',
    component: () => import('./components/Home.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme du CNSCL" }],
    },
  },
  {
    path: '/local-content',
    name: 'local-content',
    component: () => import('./components/local-content/welcome.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme du CNSCL" }],
    },
  },
  {
    path: '/local-content/step/:companyName/',
    component: () => import('./components/local-content.vue'),
    props: true,
    children: [
      {
        path: '',
        component: () => import('./components/local-content/job-step.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'Emploi' }],
        },
      },
      {
        path: '/local-content/step/:companyName/salary',
        component: () => import('./components/local-content/salary-step.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'Salaires' }],
        },
      },
      {
        path: '/local-content/step/:companyName/training',
        component: () => import('./components/local-content/training-step.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'Formations' }],
        },
      },
      {
        path: '/local-content/step/:companyName/supply',
        component: () => import('./components/local-content/supply-step.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'Approvisionnement en biens - travaux et services' }],
        },
      },
    ],
  },
  {
    path: '/home/appels-offre',
    name: 'A0',
    component: () => import('./components/AppelOffre.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme du CNSCL" }],
    },
  },
  {
    path: '/home/ami',
    name: 'AMI',
    component: () => import('./components/ami/Ami.vue'),
    meta: {
      breadcrumb: [{ parent: 'UI Kit', label: "Bienvenue sur la plateforme du CNSCL" }],
    },
  },
  {
    path: '/home/prestataires',
    name: 'ami-list-prestataires',
    component: () => import('./pages/HomePrestataire.vue'),
    meta: {
      breadcrumb: [{ parent: 'Accueil', label: "Publications" }],
    },
  },
  {
    path: '/menu',
    component: () => import('./components/MenuDemo.vue'),
    children: [
      {
        path: '',
        component: () => import('./components/menu/PersonalDemo.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'CNSCL' }],
        },
      },
      {
        path: '/menu/seat',
        component: () => import('./components/menu/SeatDemo.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'CNSCL' }],
        },
      },
      {
        path: '/menu/payment',
        component: () => import('./components/menu/PaymentDemo.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'CNSCL' }],
        },
      },
      {
        path: '/menu/confirmation',
        component: () => import('./components/menu/ConfirmationDemo.vue'),
        meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'CNSCL' }],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./pages/Register.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./pages/Profile.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('./pages/Company.vue')
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  }
});

export default router;