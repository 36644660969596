<template>
  <div class="layout-topbar">
    <div class="topbar-left">
      <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
        <i class="pi pi-chevron-left"></i>
      </a>

      <div class="horizontal-logo">
        <!-- <img
                    id="logo-horizontal"
                    src="assets/layout/images/logo-dark.svg"
                    alt="diamond-layout"
                />-->
      </div>

      <span class="topbar-separator"></span>
      <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
        <template v-if="$route.meta.breadcrumb">
          <span>{{ $route.meta.breadcrumb[0].label }}</span>
        </template>
      </div>

      <!-- <img
                id="logo-mobile"
                class="mobile-logo"
                src="assets/layout/images/logo-dark.svg"
                alt="diamond-layout"
            />-->
    </div>

    <AppMenu
      class="disable-scroll"
      :model="menu"
      :layoutMode="layoutMode"
      :active="menuActive"
      :mobileMenuActive="staticMenuMobileActive"
      @menu-click="onMenuClick"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    ></AppMenu>

    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
      <ul class="topbar-menu">
        <li v-if="user?.entreprise?.logo"><img :src="user?.entreprise.logo" width="50" /></li>
        <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
          <i class="pi pi-user" /> &nbsp;
          <span class="profile-name">{{ name }}</span>
        </li>
        <li>
          <SplitButton
            :model="guides"
            v-tooltip="'Guides d\'utilisation'"
            class="p-button-info"
            icon="pi pi-question-circle"
            style="font-size: 0.3em"
          >
          </SplitButton>
        </li>
        <li style="margin-top: 6px">
          <Button
            @click="subscribeToThisYear()"
            v-show="isTrialMode() && !isPreviousSubscAndLastToTrial()"
            label="Se réabonner"
            class="p-button p-button-primary p-mb-2"
          />
          <Button
            @click="onSuspendToggle"
            v-show="hasAdminRole"
            label="Suspendre"
            class="p-button p-button-danger p-mb-2"
          />
        </li>
        <li style="margin-top: 6px">
          <Button @click="logout" label="Se déconnecter" class="p-button-outlined p-button-danger p-mr-2 p-mb-2" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppMenu from './AppMenu';
import http from './services/http.service';
import { mapActions } from 'vuex';
import LoggedUserMixin from './mixins/logged-user';

export default {
  mixins: [LoggedUserMixin],
  name: 'AppTopbar',
  emits: [
    'menu-click',
    'menuitem-click',
    'root-menuitem-click',
    'menu-button-click',
    'search-click',
    'topbar-notification',
    'topbar-user-menu',
    'right-menubutton-click',
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    layoutMode: String,
    menu: Array,
    menuActive: Boolean,
    staticMenuMobileActive: Boolean,
  },
  data() {
    return {
      today: new Date(),
      guides: [
        {
          label: 'Guide inscription',
          command: () => {
            window.open(
              '/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Module Inscription.pdf',
              ''
            );
          },
        },
        {
          label: 'Guide PPM',
          command: () => {
            window.open('/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Module PPM.pdf', '');
          },
        },
        {
          label: 'Guide AO',
          command: () => {
            window.open('/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Module AO.pdf', '');
          },
        },
        {
          label: 'Guide AMI',
          command: () => {
            window.open('/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Module AMI.pdf', '');
          },
        },
        {
          label: 'Guide Publication',
          command: () => {
            window.open('/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Publications.pdf', '');
          },
        },
      ],
      items: [],
      isAdmin: false,
      name: '',
      user: null,
    };
  },
  components: {
    AppMenu,
  },
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  async mounted() {
    if (localStorage.getItem('token')) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      };
      const res = await http.get('/users/getProfile', config);
      this.user = res.data;
      this.name = this.user.firstName + ' ' + this.user.lastName;
      if (
        this.user.roles.length > 0 &&
        (this.user.roles[0].name === 'ROLE_ADMIN' || this.user.roles[0].authorities === 'VALIDATEUR')
      ) {
        this.isAdmin = true;
      }
    } else {
      // TODO: To remove.
      this.$router.push('/login');
    }
  },
  computed: {
    hasAdminRole: () => {
      const storage = JSON.parse(localStorage.getItem('user'));
      let isAdmin = false;
      if (storage?.typ === 'JWT'){
        isAdmin = storage.roles?.reduce((h, role) => h || role?.toLowerCase().includes('admin'), false);
      }else{
        isAdmin = storage.roles?.reduce((h, role) => h || role.name?.toLowerCase().includes('admin'), false);
      } 
      return isAdmin;
    },
  },
  methods: {
    ...mapActions({
      subscribeToThisYear: 'dialog/subscribeToThisYear',
      onSuspendToggle: 'dialog/revokeAccounts',
    }),
    isPreviousSubscAndLastToTrial() {
      const length = this.user?.entreprise?.abonnements?.length;
      const currentSubscription = this.getCurrentSubscription(this.user);

      if (length > 1) {
        const lastSubscription = this.user?.entreprise?.abonnements[0];
        if (
          this.isTrialPaymentPending(lastSubscription) &&
          (this.isSuscriptionSuspended(currentSubscription) || this.isSuscriptionTrial(currentSubscription))
        ) {
          return true;
        }
      } else {
        return;
      }
    },
    isTrialMode() {
      // let last_index = this.user?.entreprise?.abonnements.length;
      if (
        this.user?.entreprise?.abonnements[0]?.statut === 'TRIAL' ||
        this.user?.entreprise?.abonnements[0]?.statut === 'ECHU'
      ) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    onMenuClick(event) {
      this.$emit('menu-click', event);
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event);
    },
    onMenuButtonClick(event) {
      this.$emit('menu-button-click', event);
    },
    onSearchClick(event) {
      this.$emit('search-click', event);
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.$emit('topbar-notification', event);
    },

    onRightMenuClick(event) {
      this.$emit('right-menubutton-click', event);
    },
    isMobile() {
      return window.innerWidth <= 1091;
    },
  },
};
</script>
<style>
.disable-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.disable-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
