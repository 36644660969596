import axios from 'axios';

export const baseURLv2 = process.env.VUE_APP_BACKEND_URL_v2; //'https://api-plateforme.cnscl.sn/api/v1';
const Http = axios.create({ baseURL: baseURLv2 });

Http.interceptors.request.use(function (config) {
  const userToken = localStorage.getItem('token');
  if (userToken === null) return config;

  config.headers.Authorization = `Bearer ${userToken}`;
  return config;
});

Http.interceptors.response.use(function (response) {
  if (response.status === 403) window.location.reload();
  return response;
});

Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default Http;
