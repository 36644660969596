import httpService from '@/services/http.service';
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import prestatairesHelper from '@/store/plugins/prestataires';
import AppelsHelpers from '@/store/plugins/appels';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';

const initialState = {
  isEditing: false,
  isLoading: false,
  isLoaded: false,
  isSubmitting: 'NO',
  referenceKeywords: '',
  companyNameKeyword: '',
  isDeleting: false,
  prestataires: [],
  entities: {},
  links: {},
  thread: {},
  meta: {},
  errors: {
    validation: {},
  },
  selectedEntityId: null,
};

// TODO: Handle errors notification.

const actions = {
  fetchAllByPlanId({ commit }, { planId }) {
    commit('setIsEditing', true);
    httpService.get(`/appel-manifestations-interets/entreprise/${planId}`).then((response) => {
      commit('setEntities', { entities: response });
      commit('setIsEditing', false);
    });
  },

  fetchAll({ commit }, payload) {
    commit('setIsLoading', true);

    const q = payload && (payload.queryParams || null);
    httpService.get(getUrlWithQueryParams('/appel-manifestations-interets', q)).then((response) => {
      commit('setEntities', { entities: response.data });
      commit('setIsLoading', false);
    });
  },

  fetchThread({ commit }, payload) {
    commit('setIsLoading', true);

    const q = payload && (payload.queryParams || null);
    httpService.get(getUrlWithQueryParams('/appel-manifestations-interets', q)).then((response) => {
      commit('setThread', { entities: response.data });
      commit('setIsLoading', false);
    });
  },

  fetchOne({ commit, dispatch }, { id }) {
    commit('setIsLoading', true);
    httpService.get(`/appel-manifestations-interets/${id}`).then((response) => {
      commit('setEntity', { entity: response.data });
      if (response.data.id) dispatch('fetchAllPrestataires', { id });
    });
  },

  // TODO - Server should filter by company with role 'Prestataire' not only "domaine metier".
  fetchPrestatairesById({ commit }, { id }) {
    httpService.get(`/appel-manifestations-interets/${id}/findPrestataires`).then((response) => {
      commit('setPrestataires', { entities: response.data });
      commit('setIsLoading', false);
    });
  },

  createOrUpdate({ commit, dispatch }, { data, payload }) {
    commit('setIsSubmitting', SUBMIT_REQUESTED);
    const q = payload && (payload.queryParams || null);
    const url = data.id
      ? getUrlWithQueryParams(`/appel-manifestations-interets/${data.id}`, q)
      : getUrlWithQueryParams('/appel-manifestations-interets', q);
    const promise = data.id === null ? httpService.post(url, data) : httpService.put(url, data);

    promise
      .then((response) => {
        const entity = response.data;
        commit('setEntity', { entity });
        commit('setIsEditing', false);
        commit('setIsSubmitting', SUBMIT_SUCCEED);
        if (payload && payload.queryParams && payload.queryParams.flag === 'SET_NEW_STATUS') return;
        dispatch('ppm/fetchCurrentUserPlans', null, { root: true });
      })
      .catch(() => {
        commit('setIsSubmitting', SUBMIT_FAILED);
      });
  },

  attributeToEnterprises({ commit, dispatch }, { data, payload }) {
    commit('setIsSubmitting', SUBMIT_REQUESTED);
    const q = payload && (payload.queryParams || null);
    const url = getUrlWithQueryParams(`/appel-manifestations-interets/${data.id}/setAttribution`, q);
    const promise = httpService.post(url, data);

    promise
      .then((response) => {
        commit('setEntity', { entity: response.data });
        commit('setIsEditing', false);
        commit('setIsSubmitting', SUBMIT_SUCCEED);
        //TODO: To refactor.
        if (payload && payload.queryParams && payload.queryParams.flag === 'SET_NEW_STATUS') return;
        dispatch('ppm/fetchCurrentUserPlans', null, { root: true });
      })
      .catch(() => {
        commit('setIsSubmitting', SUBMIT_FAILED);
      });
  },

  delete({ commit }, { amiId: id }) {
    commit('setIsDeleting', true);
    httpService.delete(`/appel-manifestations-interets/${id}`);
    commit('unsetEntity', { attr: id });
    commit('setIsDeleting', false);
    // TODO: TO correct !
  },

  setState({ commit }, { id, state }) {
    commit('setIsSubmitting', SUBMIT_REQUESTED);
    httpService
      .put(`/appel-manifestations-interets/${id}?flag=SET_NEW_STATUS`, { etat: state })
      .then((response) => {
        const entity = response.data;
        commit('setEntity', { entity });
        commit('setIsEditing', false);
        commit('setIsSubmitting', SUBMIT_SUCCEED);
      })
      .catch(() => {
        commit('setIsSubmitting', SUBMIT_FAILED);
      });
  },
};

const mutations = {
  ...helpers.mutations,
  ...prestatairesHelper.mutations,
  setThread(state, { entities, attr }) {
    state.thread = {};
    state.thread = entities.data.reduce((dict, entity) => ({ ...dict, [entity[attr || 'id']]: entity }), {});
    state.links = entities.links;
    state.meta = entities.meta;
  },
  setReferenceKeyword(state, { keyword }) {
    state.referenceKeywords = keyword;
  },
};
const getters = {
  ...helpers.getters,
  ...prestatairesHelper.getters,
  ...AppelsHelpers.getters,
  getThread: (state) => {
    const data = state.thread;
    return Object.keys(data).map((id) => data[id]);
  },
  getAllEntitiesByReferencesOrNot(state, _getters) {
    return _getters.getAllEntities?.filter((entity) =>
      entity.reference.toLowerCase().includes(state.referenceKeywords.toLowerCase())
    );
  },
};

export const ami = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
