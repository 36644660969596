import axios from 'axios';
console.log( process.env);
export const baseURL = process.env.VUE_APP_BACKEND; //'https://api-plateforme.cnscl.sn/api/v1';
const http = axios.create({ baseURL });

http.interceptors.request.use(function (config) {
  const userToken = localStorage.getItem('token');
  if (userToken === null) return config;

  config.headers.Authorization = `Bearer ${userToken}`;
  return config;
});

export default http;
