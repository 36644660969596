import httpService from "@/services/http.service"

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,

  state: initialState,

  actions: {
    login() {
      /*return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );*/
    },

    logout({ commit }) {
      // AuthService.logout();
      commit('logout');
    },

    register() {
      /*return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );*/
    },

    getProfile({ commit }) {
      httpService.get("/users/getProfile").then((response) => {
        commit("setUser", response.data);
      })
    },
  },

  getters: {
    user: () => JSON.parse(localStorage.getItem("user")),
    userEntreprise: (_, getters) => getters.user.entreprise,
    userDomaineMetiers: (_, getters) => getters.userEntreprise.domaineMetiers
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },

    setUser: (state, user) => {
      state.user = user;
    }
  }
};