const initialState = {
  enableWarningSubscriptionDialog: false,
  enableRevokeDialog: false,
  enablePlanSummaryDialog:false
};

export const dialog = {
  namespaced: true,

  state: initialState,
  actions: {
    subscribeToThisYear({ commit }) {
      commit('toggleEnableWarningSubsc');
    },
    revokeAccounts({ commit }) {
      commit('toggleRevokeDialogs');
    },
    loadPlanSummary({ commit }) {
      commit('toggleLoadSummaryDialogs');
    },
  },

  getters: {
    getenabledWarningSubscriptionDialog: (state) => {
      return state.enableWarningSubscriptionDialog;
    },
    getRevokeDialogStatus: (state) => {
      return state.enableRevokeDialog;
    },
    isPlanSummaryDialogsOpened: (state) => {
      return state.enablePlanSummaryDialog;
    },
  },

  mutations: {
    toggleEnableWarningSubsc(state) {
      state.enableWarningSubscriptionDialog = !state.enableWarningSubscriptionDialog;
    },
    toggleRevokeDialogs(state) {
      state.enableRevokeDialog = !state.enableRevokeDialog;
    },
    toggleLoadSummaryDialogs(state) {
      state.enablePlanSummaryDialog = !state.enablePlanSummaryDialog;
    },
  },
};
