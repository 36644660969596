import httpService from '@/services/http.service';
import { SERVER_VALIDATION_ERROR } from '@/store/enums';

export const mapQueryParamsToUrl = (queryParams) => {
  return Object.keys(queryParams).reduce((query, key) => query + `&${key}=${queryParams[key]}`, '');
};

export const getUrlWithQueryParams = (url, queryParams) => {
  if (queryParams) url = `${url}?${mapQueryParamsToUrl(queryParams)}`;
  return url;
};

export const createOrUpdateHelper = ({ url, data, commit, httpService, successCallback }) => {
  const promise = data.id === null || data.id === undefined ? httpService.post(url, data) : httpService.put(url, data);

  const defaultBehaviour = (response) => {
    const entity = response.data;
    commit('setEntity', { entity });
    commit('setIsEditing', false);
    // commit("setIsSubmitting", false);
  };

  promise.then(successCallback || defaultBehaviour).catch((error) => {
    const errorResponse = error.response;
    const isValidationError = errorResponse.data.message === SERVER_VALIDATION_ERROR;

    const errors = {
      validationError: isValidationError,
      errorList: errorResponse.data.errors,
    };
    if (isValidationError) commit('setErrors', { errors });
    // commit("setIsSubmitting", false);
  });
};

export default {
  actions: {
    fetchAll({ commit }, payload) {
      commit('setIsEditing', true);

      const q = payload && (payload.queryParams || null);
      httpService.get(getUrlWithQueryParams('/natures-prestations', q)).then((response) => {
        commit('setEntities', { entities: response.data });
        commit('setIsEditing', false);
      });
    },
  },

  mutations: {
    setIsEditing(state, loadingStatus) {
      state.isEditing = loadingStatus;
    },

    setIsLoading(state, loadingStatus) {
      state.isLoading = loadingStatus;
    },

    setIsLoaded(state, loadedStatus) {
      state.isLoaded = loadedStatus;
    },

    setIsDeleting(state, loadedStatus) {
      state.isDeleting = loadedStatus;
    },

    setIsSubmitting(state, loadedStatus) {
      state.isSubmitting = loadedStatus;
    },

    setEntities(state, { entities, attr }) {
      try {
        state.entities = {};
        state.entities = entities.data.reduce((dict, entity) => ({ ...dict, [entity[attr || 'id']]: entity }), {});
        state.links = entities.links;
        state.meta = entities.meta;
      } catch (error) {
        // console.log({ error });
        // console.log({ data: entities.data });
      }
    },

    setEntity(state, { entity, attr }) {
      state.entities = { ...state.entities, [entity[attr || 'id']]: entity };
    },

    unsetEntity(state, { attr }) {
      const entities = state.entities;
      delete entities[attr];
      state.entities = entities;
    },

    setErrors(state, { errors }) {
      if (errors.validationError)
        state.errors = {
          validation: errors.errorList.reduce((h, item) => ({ ...h, [item.field]: item.message }), {}),
        };
    },

    setSelectedEntityId(state, entityId) {
      state.selectedEntityId = entityId;
    },
  },

  getters: {
    getEntities: (state) => {
      return state.entities;
    },

    getAllEntities: (state) => {
      const data = state.entities;
      return Object.keys(data).map((id) => data[id]);
    },

    getErrors: (state) => state.errors,

    getIsEditing(state) {
      return state.isEditing;
    },

    getIsSubmitting(state) {
      return state.isSubmitting;
    },

    getIsDeleting(state) {
      return state.isDeleting;
    },

    getIsLoading(state) {
      return state.isLoading;
    },

    getIsLoaded(state) {
      return state.isLoaded;
    },

    getSubmitting(state) {
      return state.isSubmitting;
    },

    getEntity(state) {
      return state.entities[state.selectedEntityId];
    },
  },
};
