import httpService from '@/services/http.service';
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';
import { mergeSort } from '@/store/algorithms';
import moment from 'moment';

const initialState = {
  isEditing: false,
  isLoading: false,
  isLoaded: false,
  isSubmitting: 'NO',
  isDeleting: false,
  prestataires: [],
  entities: {},
  links: {},
  meta: {},
  errors: {
    validation: {},
  },
  selectedEntityId: null,
  selectableAppelsOffres: [],
  selectableManifestationsInterets: [],
};

// TODO: Handle errors notification.

const actions = {
  fetchAll({ commit }, payload) {
    commit('setIsEditing', true);

    const q = payload && (payload.queryParams || null);
    httpService.get(getUrlWithQueryParams('/projets-marches', q)).then((response) => {
      commit('setEntities', { entities: response.data });
      commit('setIsEditing', false);
    });
  },

  fetchAllForAmiByEntrepriseIdAnnee({ commit }, { entrepriseId, annee }) {
    commit('setIsLoading', true);
    httpService.get(`/projets-marches/appel-manifestation/${entrepriseId}/${annee}`).then((response) => {
      commit('setSelectableManifestationsInterets', response.data);
      commit('setIsLoading', false);
    });
  },

  fetchAllForAoByEntrepriseIdAnnee({ commit }, { entrepriseId, annee }) {
    commit('setIsLoading', true);
    httpService.get(`/projets-marches/appel-offre/${entrepriseId}/${annee}`).then((response) => {
      commit('setSelectableAppelsOffres', response.data);
      commit('setIsLoading', false);
    });
  },

  fetchOne({ commit }, { id }) {
    commit('setIsLoading', true);
    httpService.get(`/projets-marches/${id}`).then((response) => {
      commit('setEntity', { entity: response.data });
    });
  },

  createOrUpdate({ commit }, { data }) {
    commit('setIsSubmitting', SUBMIT_REQUESTED);
    const url = data.id ? `/projets-marches/${data.id}` : '/projets-marches';

    const promise = data.id === null || !data.id ? httpService.post(url, data) : httpService.put(url, data);

    promise
      .then((response) => {
        commit('ppm/addProjetMarcheToPlan', response.data, { root: true });
        commit('setIsSubmitting', SUBMIT_SUCCEED);
        commit('setIsEditing', false);
      })
      .catch(() => {
        commit('setIsSubmitting', SUBMIT_FAILED);
      });
  },

  delete({ commit }, { id }) {
    commit('setIsDeleting', true);
    httpService.delete(`/projets-marches/${id}`).then(() => {
      commit('setIsDeleting', false);
      commit('ppm/removeProjetMarcheToPlan', id, { root: true });
    });
  },
};

const mutations = {
  ...helpers.mutations,

  setSelectableAppelsOffres: (state, projetsMarches) => {
    state.selectableAppelsOffres = projetsMarches;
  },

  setSelectableManifestationsInterets: (state, projetsMarches) => {
    state.selectableManifestationsInterets = projetsMarches;
  },
};
const getters = {
  ...helpers.getters,

  getPlansProjetsMarches: (state, getters, rootState, rootGetters) => {
    let projetsMarches = [];
    rootGetters['ppm/getAllEntities'].forEach((ppm) => {
      projetsMarches = projetsMarches.concat(ppm.projetMarches);
    });
    return projetsMarches;
  },

  getSelectableAppelsOffres: (state) => {
    return state.selectableAppelsOffres;
  },

  getSelectableManifestationsInterets: (state) => {
    return state.selectableManifestationsInterets;
  },

  getProjetMarcheFromPlan: (state, getters) => {
    return getters.getPlansProjetsMarches
      ? getters.getPlansProjetsMarches.find((projetMarche) => projetMarche.id === state.selectedEntityId)
      : {};
  },

  // TODO: Add by annee.
  getThread: (state, getters, rootState, rootGetters) => {
    const flags = ['ATTRIBUE', 'OUVERT', 'FERME', 'CLOTURE', 'VALIDE', 'CREE'];

    let appelsOffres = rootGetters['appelsOffres/getThread'];
    let amisOffres = rootGetters['ami/getThread'];
    let data = appelsOffres.concat(amisOffres).filter((item) => flags.includes(item.etat));
    mergeSort(data, 0, data.length - 1);
    return data;
  },

  getThreadByDate: (_, getters) => {
    const data = getters.getThread.reduce((h, item) => {
      const k = moment(item.datePublication).locale('fr').format('MMMM, YYYY');
      const v = h[k] || [];
      v.push(item);
      return { ...h, [k]: v };
    }, {});
    return data;
  },
};

export const projetsMarches = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
