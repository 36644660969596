import httpService from "@/services/http.service"
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';

const initialState = {
  isEditing: false,
  isLoading: false,
  isLoaded: false,
  isSubmitting: false,
  isDeleting: false,
  prestataires: [],
  entities: {},
  links: {},
  meta: {},
  errors: {
    validation: {}
  },
  selectedEntityId: null
}

// TODO: Handle errors notification.

const actions = {
  fetchAll({ commit }, payload) {
    commit("setIsEditing", true);

    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/natures-prestations", q))
      .then((response) => {
        commit("setEntities", { entities: response.data });
        commit("setIsEditing", false);
      })
  },

  fetchOne({ commit }, { id }) {
    commit("setIsLoading", true);
    httpService.get(`/natures-prestations/${id}`)
      .then((response) => {
        commit("setEntity", { entity: response.data });
      })
  },

  createOrUpdate({ commit }, { data }) {
    commit("setIsSubmitting", SUBMIT_REQUESTED);
    const url = data.id === undefined
      ? '/natures-prestations'
      : `/natures-prestations/${data.id}`;
    const promise = data.id === undefined ? httpService.post(url, data) : httpService.put(url, data);

    promise.then((response) => {
      const entity = response.data;
      commit("setEntity", { entity })
      commit("setIsEditing", false);
      commit("setIsSubmitting", SUBMIT_SUCCEED);
    }).catch(() => {
      commit("setIsSubmitting", SUBMIT_FAILED);
    })
  },

  delete({ commit }, { id }) {
    commit("setIsDeleting", true);
    httpService.delete(`/natures-prestations/${id}`)
      .then(() => {
        commit("unsetEntity", { attr: id })
        commit("setIsDeleting", false);
      })
  },
};

const mutations = {
  ...helpers.mutations
};
const getters = {
  ...helpers.getters
};

export const naturePrestation = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
}