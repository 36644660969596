import httpService from '@/services/http.service';
import helpers, { getUrlWithQueryParams } from '@/store/plugins/helpers';
import { LOAD_REQUESTED, LOAD_FAILED, LOAD_SUCCEED } from '@/mixins/app-conts';

const initialState = {
  isEditing: false,
  isLoading: false,
  isLoaded: false,
  isSubmitting: 'NO',
  isDeleting: false,
  entities: {},
  links: {},
  meta: {},
  errors: {
    validation: {},
  },
  selectedEntityId: null,
};

const actions = {
  fetchAll({ commit }, payload) {
    commit('setIsEditing', true);

    const q = payload && (payload.queryParams || null);
    httpService
      .get(getUrlWithQueryParams('/enterprises', q))
      .then((response) => {
        commit('setEntities', { entities: response.data });
        commit('setIsLoading', LOAD_SUCCEED);
      })
      .catch(() => {
        commit('setIsLoading', LOAD_FAILED);
      });
  },

  fetchOne({ commit }, { id }) {
    commit('setIsLoading', LOAD_REQUESTED);
    httpService
      .get(`/enterprises/${id}`)
      .then((response) => {
        commit('setEntity', { entity: response.data });
        commit('setIsLoading', LOAD_SUCCEED);
      })
      .catch(() => {
        commit('setIsLoading', LOAD_FAILED);
      });
  },
};

const mutations = {
  ...helpers.mutations,
};
const getters = {
  ...helpers.getters,
  getDonneursOffres: (_, getters) => {
    // TODO: Filter it from api.
    // const status = ["MAJOR", "RANK_UN", "RANK_DEUX"];
    return getters.getAllEntities;
    // .filter(entreprise => status.includes(entreprise.rangSouscriptionEnCours))
  },
};

export const entreprises = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
