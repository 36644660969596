import httpService from "@/services/http.service"
import  { getUrlWithQueryParams } from '@/store/plugins/helpers';

const initialState = {
    planList:[],
    jobList: [],
    salariesList: [],
    trainingsList: [],
    suppliesList: [],
    trainingsInTable:[],
    planSummary:[],
    planInfos:[],
    toReturnList:[]
};

const actions = {

  //FETCH DATA

  fetchPlanSummary({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/summary", q))
      .then((response) => {
        console.log(response.data);
        commit("setPlanSummary", { entities: response.data });
      })
  },
  fetchAllJobs({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/section?heading=JOBS", q))
      .then((response) => {
        console.log(response.data);
        commit("setJobs", { entities: response.data });
      })
  },
  fetchAllSalaries({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/section?heading=SALARIES", q))
      .then((response) => {
        console.log(response.data);
        commit("setSalaries", { entities: response.data });
      })
  },
  fetchAllTrainings({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/section?heading=TRAININGS", q))
      .then((response) => {
        console.log(response.data);
        commit("setTrainings", { entities: response.data });
      })
  },
  fetchAllSupplies({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/section?heading=SUPPLY", q))
      .then((response) => {
        console.log(response.data);
        commit("setSupplies", { entities: response.data });
      })
  },
  fetchAllTrainingsInTable({ commit }, payload) {
    const q = payload && (payload.queryParams || null)
    httpService.get(getUrlWithQueryParams("/lcp-surveys/2023/trainings", q))
      .then((response) => {
        console.log(response.data);
        commit("setTrainingsInTable", { entities: response.data });
      })
  },

  //ADD - UPDATES - DELETES

  submitTraining({ dispatch }, data) {
    const q =  null;
    const url = getUrlWithQueryParams('/lcp-forms/submit-training', q);
    const promise = httpService.post(url, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });

    promise
      .then((response) => {
        console.log(response.data);
        dispatch('fetchAllTrainingsInTable');
      });
  },

  submitAnswers({ dispatch }, data) {
    const q =  null;
    const url = getUrlWithQueryParams('/lcp-forms/submit-answers', q);
    const promise = httpService.post(url, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });

    promise
      .then((response) => {
        console.log(response.data);
        Promise.all([
            dispatch('fetchAllJobs'),
            dispatch('fetchAllSalaries'),
            dispatch('fetchAllTrainings'),
            dispatch('fetchAllTrainingsInTable'),
            dispatch('fetchAllSupplies'),
          ]);
      });
  },

  deleteTraining({ dispatch }, { trainingId: id }) {
    httpService.delete(`/lcp-forms/${id}/delete-training`).then(e=>{
        dispatch('fetchAllTrainingsInTable');
        console.log(e);
    });
  },


  // MANAGEMENT

  updateStatus(_, { data, lcpId: id }) {
    const promise = httpService.post(`/lcp-management/${id}/update-status`, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });

    promise
      .then((response) => {
        console.log(response.data);
      });
  },

  selectToReturn({ commit }, { toReturn: toReturn }){
    commit("setToReturn", toReturn);
  },

  returnPlan({ dispatch }, { toReturnList: toReturnList,lcpId: id }){
    let data = {
        event: "REJECT",
        answers: []
      };
      console.log(toReturnList);
      data.answers = toReturnList;
    dispatch("updateStatus", { data, lcpId: id });
  },

  validatePlan({ dispatch }, id){
    let data = {
      event: "VALIDATE",
      answers: []
    };
    dispatch("updateStatus", { data, lcpId: id });
  },

  fetchPlans({ commit },{ params: params}) {
    httpService.get(`lcp-management/list?${params}`).then(response=>{
        commit("setPlanList", { entities: response.data });
        console.log(response)
    });
  },

  fetchPlanInfos({ commit },{ params: params}) {
    httpService.get(`/lcp-surveys/${params}/infos`)
      .then((response) => {
        console.log(response.data);
        commit("setPlanInfos", { entities: response.data });
      })
  },

}

const mutations = {
    setToReturn(state, value) {
      let found = [];
      console.log(value);
      let Obj = {
        id: value?.answers?.id,
        validityStatus: "F_INVALID",
        comment: value?.answers?.comment
      };
      found = state.toReturnList.filter(e=>e.id === value?.answers?.questionId); 
      if(found.length > 0){
        const index = state.toReturnList.indexOf(Obj);
        state.toReturnList.splice(index, 1);
        // console.log(index,x)
        // state.toReturnList = x;
      }else{
        state.toReturnList.push(Obj);

      }
      // console.log(Obj);
      // console.log(state.toReturnList);
    },
    setPlanList(state, value) {
        state.planList = value;
    },
    setPlanSummary(state, value) {
        state.planSummary = value;
    },
    setPlanInfos(state, value) {
        state.planInfos = value;
    },
    setJobs(state, value) {
        state.jobList = value;
    },
    setSalaries(state, value) {
        state.salariesList = value;
    },
    setTrainings(state, value) {
        state.trainingsList = value;
    },
    setSupplies(state, value) {
        state.suppliesList = value;
    },
    setTrainingsInTable(state, value) {
        state.trainingsInTable = value;
    },
}

const getters = {
    getToReturn: (state) => {
      const data = state.toReturnList;
      return data;
  },
  getToReturnSelected: (state,value) => {
      let found = [];
      found = state.toReturnList.filter(e=>e.id === value?.answers?.questionId);
      if(found > 0) return true;
      return false;
  },
    getPlanList: (state) => {
        const data = state.planList?.entities;
        return data;
    },
    // JOBS
    getJobsQuestionsListMock: (state) => {
        const data = state.jobList?.entities?.questions;
        return data;
    },
    getJobsAnswersListMock: (state) => {
        const data = state.jobList?.entities?.answers;
        return data;
    },

    // SALARIES
    getSalariesQuestionsListMock: (state) => {
        const data = state.salariesList?.entities?.questions;
        return data;
    },
    getSalariesAnswersListMock: (state) => {
        const data = state.salariesList?.entities?.answers;
        return data;
    },

    // TRAININGS
    getTrainingsQuestionsListMock: (state) => {
        const data = state.trainingsList?.entities?.questions;
        return data;
    },
    getTrainingsAnswersListMock: (state) => {
        const data = state.trainingsList?.entities?.answers;
        return data;
    },

    // SUPPLIES
    getSuppliesQuestionsListMock: (state) => {
        const data = state.suppliesList?.entities?.questions;
        return data;
    },
    getSuppliesAnswersListMock: (state) => {
        const data = state.suppliesList?.entities?.answers;
        return data;
    },

    // TRAINING IN TABLE
    getTrainingsInTable: (state) => {
        const data = state.trainingsInTable?.entities;
        return data;
    },

    // PLAN SUMMARY
    getPlanSummary: (state) => {
        const data = state.planSummary?.entities;
        return data;
    },

    getPlanInfos: (state) => {
        const data = state.planInfos?.entities;
        return data;
    },
}

export const localContent = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
}