export const BackendUrls = {
  RegistrationUrl: () => '/v2/registration',
  SubscriptionUpdateUserUrl: () => '/v2/subscriptions/update-user',
  SubscriptionUpdateEnterpriseUrl: () => '/v2/subscriptions/update-enterprise',
  SubscriptionValidationRequestUrl: (subscriptionId) => `/v2/subscriptions/${subscriptionId}/submit-subscription`,
  SubmitPaymentUrl: (subscriptionId) => `/v2/payments/${subscriptionId}/confirm-payment`,
  SubmitReSubscPaymentUrl: (subscriptionId, year) => `/v1/subscriptions/${subscriptionId}/re-activate/${year}`,
  GetListSubscriptions: (parameters) => `/v1/abonnements?${parameters}`,
  GetListPayments: () => `/subscriptions/export`,
  PostRejectSubscription: (enterpriseId, subscriptionId) =>
    `/v2/subscriptions/${enterpriseId}/reject-subscription/${subscriptionId}`,
  PostValidateSubscription: (enterpriseId, subscriptionId) =>
    `/v2/subscriptions/${enterpriseId}/validate-subscription/${subscriptionId}`,
  PostValidatePayment: (subscriptionId) => `/v2/payments/${subscriptionId}/validate-payment`,
  PostRejectPayment: (subscriptionId) => `/v2/payments/${subscriptionId}/reject-payment`,
};
