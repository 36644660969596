export default {
  mutations: {
    setPrestataires(state, { entities }) {
      state.prestataires = entities.reduce((dict, entity) => ({ ...dict, [entity.id]: entity }), {});
    },

    setCompanyNameKeyword(state, { keyword, rank }) {
      state.companyNameKeyword = keyword;
      state.companyRankKeyword = rank;
    },
  },

  getters: {
    getPrestataires: (state) => {
      const data = state.prestataires;
      return Object.keys(data).map((id) => data[id]);
    },

    getPrestatairesByName: (state) => {
      let data = state.prestataires;

      data = Object.keys(data)
        .map((id) => data[id])
        .filter((p) => p.name.toLowerCase().includes(state.companyNameKeyword.toLowerCase()));

      if (state.companyRankKeyword)
        data = data.filter((enterprise) => enterprise.rangSouscriptionEnCours === state.companyRankKeyword);

      return data;
    },
  },
};
