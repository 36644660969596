import { createStore } from 'vuex';

import { auth } from './auth.module';
import { ami } from './ami.module';
import { pays } from './pays.module';
import { ppm } from './ppm.module';
import { appelsOffres } from './appels-offre.module';
import { naturePrestation } from './nature-prestation.module';
import { projetsMarches } from './projets-marches.module';
import { stats } from '@/store/stats.module';
import { domainesMetiers } from '@/store/domaines-metiers.module';
import { entreprises } from '@/store/entreprises.module';
import { categories } from '@/store/categories.module';
import { tarifs } from '@/store/tarifs.module';
import { subscriptions } from '@/store/subscriptions.module';
import { dialog } from '@/store/extras/dialog.module';
import { localContent } from '@/store/extras/local-content.module';

const store = createStore({
  modules: {
    appelsOffres,
    auth,
    ami,
    categories,
    domainesMetiers,
    entreprises,
    naturePrestation,
    pays,
    ppm,
    projetsMarches,
    stats,
    tarifs,
    dialog,
    subscriptions,
    localContent
  },
});

export default store;
